import React, { FC } from "react";
import { Location } from "@reach/router";
import QueryString from "query-string";

// import AddPayment from "../components/add-payment";
import EmptyLayout from "../components/empty-layout";

const IndexPage: FC = () => (
  <Location>
    {({ location }): JSX.Element => {
      const query = QueryString.parse(location.search);
      return (
        <EmptyLayout>
          {/* <AddPayment wastTokenApi={query.token as string} /> */}
        </EmptyLayout>
      );
    }}
  </Location>
);

export default IndexPage
