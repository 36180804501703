import React, { FC, ReactChild } from "react";
import { IntlProvider } from "react-intl";

import ThemeProvider from "../theme/theme-provider";
import { ToastProvider } from "../ui/toast";

import en from "../translations/en";
import fr from "../translations/fr";
import userLanguage from "./hooks/useLanguage";

interface LayoutProps {
  children: ReactChild | ReactChild[];
}

const messages: { [key: string]: { [key: string]: string } } = {
  en: en,
  fr: fr,
};

const Layout: FC<LayoutProps> = ({ children }) => {
  const userLang = userLanguage();

  return (
    <IntlProvider locale={userLang} messages={messages[userLang]} defaultLocale="fr">
      <ThemeProvider>
        <ToastProvider>
          {children}
        </ToastProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default Layout;

